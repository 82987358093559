<template>
  <v-alert
    :value="$store.state.alert.toggle"
    :color="$store.state.alert.color"
    :icon="$store.state.alert.icon"
    :dismissible="$store.state.alert.dismissible"
    dark>
    <span class="MontserratMedium" v-html="$store.state.alert.text" />
  </v-alert>
</template>

<script>
  export default
  {
    name: "AlertBar"
  }
</script>

<style scoped>

</style>